/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  const schemaOrgWebPage = {
    "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [ {
        "@type": "Question",
        "name": "Is it free to use the app?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, Manus is free to use on App Store and Google Play."
        }
      }, {
        "@type": "Question",
        "name": "How does Manus verify tradespeople?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "We contact and check the credentials of all tradespeople who sign up to Manus. Checks include photo ID, trade body certifications and public liability insurance. We manually verify a minimum of 3 references for all our tradespeople."
        }
      }, {
        "@type": "Question",
        "name": "Is my data safe?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, we adhere to GDPR guidelines and never share your information without explicit permission. Manus is registered with the Information Commissioner's Office (ICO), No. ZB035236"
        }
      }]
  }

  const companySchema = { 
    "@context": "https://schema.org",
      "@type": "Organization",
      "email": "help@manusapp.com",
      "url" : "https://manusapp.com",
      "name": "Manus",
      "member": [
        {
          "@type": "Organization"
        },
        {
          "@type": "Organization"
        }
      ]
  };

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">{JSON.stringify(companySchema)}</script>
      <script type="application/ld+json">{JSON.stringify(schemaOrgWebPage)}</script>
      </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
